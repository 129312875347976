<template>
	<div>
		<div class="text-bold text-green">Purchase Invoice Headers</div>
		<span class="text-bold text-blue"> Our Company: </span>
		{{ ourCompanyPurchaseInvoice }}<br />
		<span class="text-bold text-blue">Customer Id:</span>
		{{ customerIdPurchaseInvoice }}<br />
		<span class="text-bold text-blue">Customer Title:</span>
		{{ customerTitlePurchaseInvoice }}<br />
		<span class="text-bold text-blue">Customer Nickname:</span>
		{{ customerNicknamePurchaseInvoice }}<br />
	</div>
</template>

<script>
import usePurchaseInvoiceHeaderState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState'

export default {
	name: 'GetPurchaseInvoiceHeaders',
	setup() {
		const {
			ourCompanyPurchaseInvoice,
			customerIdPurchaseInvoice,
			customerTitlePurchaseInvoice,
			customerNicknamePurchaseInvoice,
		} = usePurchaseInvoiceHeaderState()
		return {
			ourCompanyPurchaseInvoice,
			customerIdPurchaseInvoice,
			customerTitlePurchaseInvoice,
			customerNicknamePurchaseInvoice,
		}
	},
}
</script>

<style scoped></style>
