<template>
	<div>
		<div>
			<b-tag
				type="is-info"
				size="is-large"
				class="margin-top-5"
				style="width: 420px"
			>
				Current Purchase Invoice
			</b-tag>
		</div>

		<div v-if="true">
			<div class="margin-top-20">
				<ve-table
					:rows="purchaseInvoiceLinesArray"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:searchedOptions="false"
					:perPage="perPageProp"
					maxHeight="1500px"
				>
				</ve-table>
			</div>
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					color: white;
					padding-top: 20px;
					padding-right: 30px;
					text-align: right;
					font-weight: 700;
				"
			>
				Purchase Invoice Total: {{ purchaseInvoiceTotal }}
				{{ purchaseInvoiceExchangeUnit }}
			</div>
		</div>
	</div>
</template>

<script>
import storeCurrentPurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/current-purchase-invoice/useCurrentPurchaseInvoiceState.js'
import { ref, onMounted, computed } from '@vue/composition-api'
import usePurchaseInvoiceHeaderState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'
import numeral from 'numeral'
export default {
	name: 'CurrentPurchaseInvoice',
	setup() {
		const perPageProp = ref(25)
		const { columnsList, purchaseInvoiceLinesArray } =
			storeCurrentPurchaseInvoice()
		const { purchaseInvoiceExchangeUnit } = usePurchaseInvoiceHeaderState()
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		onMounted(() => (purchaseInvoiceLinesArray.value = []))
		// delete purchase invoice line
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.goods_transaction_id !== value
			})
		}
		const onCellClick = (params) => {
			if (params.column.field === 'delete') {
				purchaseInvoiceLinesArray.value = arrayRemove(
					purchaseInvoiceLinesArray.value,
					params.row.goods_transaction_id,
				)
			}
		}
		// todo ----------------------------------------------------------------------------
		// ? Use a regular expression to match spaces and commas globally
		// eslint-disable-next-line no-useless-escape
		const regexComa = /[\,]/g
		// eslint-disable-next-line no-useless-escape
		const regexDot = /[\.]/g
		// todo ----------------------------------------------------------------------------
		// const initialValue = 0
		const purchaseInvoiceTotal = computed(() => {
			return numeral(
				purchaseInvoiceLinesArray.value.reduce(
					(acc, curr) => acc + curr.line_price_total_debit_exchange / 100,
					0,
				),
			)
				.format('0,0.00')
				.replace(regexComa, ' ')
				.replace(regexDot, ',')
		})
		return {
			purchaseInvoiceExchangeUnit: computed(
				() => `  ${purchaseInvoiceExchangeUnit.value}`,
			),
			columnsList,
			perPageProp,
			onCellClick,
			onRowDoubleClick,
			purchaseInvoiceLinesArray,
			purchaseInvoiceTotal,
		}
	},
}
</script>

<style scoped lang="scss"></style>
