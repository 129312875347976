<template>
	<div>
		<div>
			<b-button
				class="is-primary margin-top-10"
				@click="onSave('save')"
				:disabled="disableButtons"
			>
				Save Purchase Invoice
			</b-button>
			<b-button
				class="is-danger margin-top-10 margin-left-20"
				@click="onSave('lock')"
				:disabled="disableButtons"
			>
				Save & Lock Purchase Invoice
			</b-button>
		</div>
	</div>
</template>

<script>
import storePurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'
import InsertPurchaseInvoiceMutation from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/create-purchase-invoice/InsertPurchaseInvoiceMutation.graphql'
import { computed, ref, watchEffect, reactive } from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import storeCurrentPurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/current-purchase-invoice/useCurrentPurchaseInvoiceState.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid'
import storePurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/usePurchaseInvoiceState'
import MutateStockDebitAmountMutation from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/create-purchase-invoice/mutateStockDebitAmount.mutation.graphql'
import LockPurchaseInvoiceMutation from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/create-purchase-invoice/LockPurchaseInvoiceMutation.graphql'
import Store from '@/store'
export default {
	name: 'CreatePurchaseInvoice',
	setup() {
		const { purchaseInvoiceLinesArray } = storeCurrentPurchaseInvoice()
		watchEffect(() => {
			if (purchaseInvoiceLinesArray.value !== null || undefined) {
				disableButtons.value = purchaseInvoiceLinesArray.value.length <= 0
			} else {
				disableButtons.value = true
			}
		})
		const purchaseInvoiceLinesArrayToDB = computed(() => {
			return purchaseInvoiceLinesArray.value.map((item) => {
				return {
					goods_transaction_id: item.goods_transaction_id,
					transaction_type: item.transaction_type,
					stock_id: item.stock_id,
					line_info: item.line_info,
					amount_debit: parseInt(item.amount_debit),
					unit_price: parseInt(item.unit_price),
					vat_percent: parseInt(item.vat_percent),
					vat_debit: parseInt(item.vat_debit),
					invoice_price: parseInt(item.invoice_price),
					line_price_total_debit: parseInt(item.line_price_total_debit),
					vat_debit_exchange: parseInt(item.vat_debit_exchange),
					invoice_price_exchange: parseInt(item.invoice_price_exchange),
					line_price_total_debit_exchange: parseInt(
						item.line_price_total_debit_exchange,
					),
				}
			})
		})
		// todo -------------------------------------------------------------------------
		const {
			ourCompanyPurchaseInvoice,
			customerIdPurchaseInvoice,
			purchaseInvoiceIssueDate,
			purchaseInvoiceDueDate,
			purchaseInvoiceExchangeUnit,
			purchaseInvoiceExchangeRate,
			purchaseInvoiceSupplierNumber,
			purchaseInvoiceType,
		} = storePurchaseInvoiceHeaders()
		// todo ----------------------------------------------------------------------------
		const isInvoiceLocked = ref(false)
		// * -------------------------------------------------------------------
		const disableButtons = ref(true)
		// * MutateStockDebitAmountMutation
		const mutationVariablesMutateStockDebitAmountMutation = reactive({
			company_id: Store.getters.getUserCurrentCompany,
			stock_id: '',
			debit_amount: 0,
		})
		const {
			mutate: mutateStockDebitAmount,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(MutateStockDebitAmountMutation, () => ({
			variables: mutationVariablesMutateStockDebitAmountMutation,
		}))
		// * ---------------------------------------------------------------------
		// * trigger stock debit amount
		const getStockDebitAmountArray = () => {
			const arrayLength = purchaseInvoiceLinesArray.value.length
			// console.log('purchaseInvoiceLinesArray', purchaseInvoiceLinesArray.value)
			// console.log('is array', Array.isArray(purchaseInvoiceLinesArray.value))
			for (let i = arrayLength; i > 0; i--) {
				// console.log('line:', purchaseInvoiceLinesArray.value[i - 1])
				let item = purchaseInvoiceLinesArray.value[i - 1]
				// console.log('item', item)
				mutationVariablesMutateStockDebitAmountMutation.company_id =
					Store.getters.getUserCurrentCompany
				mutationVariablesMutateStockDebitAmountMutation.stock_id = item.stock_id
				mutationVariablesMutateStockDebitAmountMutation.debit_amount =
					item.amount_debit
				// console.log(
				// 	'mutationVariablesMutateStockDebitAmountMutation',
				// 	mutationVariablesMutateStockDebitAmountMutation,
				// )
				mutateStockDebitAmount()
			}
		}
		// * ----------------------------------------------------------------
		const { getUUID } = useGetUuid()
		const mutationVariable = computed(() => {
			return [
				{
					document_transaction_id: getUUID(),
					transaction_type: 'purchase invoice',
					our_company: ourCompanyPurchaseInvoice.value,
					invoice_number: `${customerIdPurchaseInvoice.value}-${purchaseInvoiceSupplierNumber.value}`,
					supplier_invoice_number: purchaseInvoiceSupplierNumber.value,
					invoice_date: purchaseInvoiceIssueDate.value,
					transaction_date: purchaseInvoiceIssueDate.value,
					invoice_due_date: purchaseInvoiceDueDate.value,
					invoice_type: purchaseInvoiceType.value,
					invoice_exchange_unit: purchaseInvoiceExchangeUnit.value,
					invoice_exchange_rate: purchaseInvoiceExchangeRate.value,
					customer_id: customerIdPurchaseInvoice.value,
					invoice_lock: isInvoiceLocked.value,
					_goods_transactions: {
						data: purchaseInvoiceLinesArrayToDB.value,
					},
				},
			]
		})

		// todo ---------------------------------------------------------------------------
		const { mutate: addNewPurchaseInvoice } = useMutation(
			InsertPurchaseInvoiceMutation,
			() => ({
				variables: {
					input: mutationVariable.value,
				},
			}),
		)
		// todo ---------------------------------------------------------------------------
		const { mutate: lockPurchaseInvoice } = useMutation(
			LockPurchaseInvoiceMutation,
			() => ({
				variables: {
					our_company: ourCompanyPurchaseInvoice.value,
					invoice_number: `${customerIdPurchaseInvoice.value}-${purchaseInvoiceSupplierNumber.value}`,
				},
			}),
		)
		// todo ---------------------------------------------------------------------------
		const {
			showSearchBox,
			showGetPurchaseInvoiceHeaders,
			showSubSummaryHeaders,
		} = storePurchaseInvoice()
		// todo -----------------------------------------------------------------------------------
		const onClose = () => {
			showSearchBox.value = true
			showGetPurchaseInvoiceHeaders.value = false
			showSubSummaryHeaders.value = false
		}
		const setPurchaseInvoiceStatus = (type) => {
			return new Promise((resolve, reject) => {
				if (type !== null || undefined) {
					switch (type) {
						case 'save':
							isInvoiceLocked.value = false
							break
						case 'lock':
							isInvoiceLocked.value = false
							break
						case 'view':
							isInvoiceLocked.value = true
							break
						default:
							isInvoiceLocked.value = false
					}
					resolve('done')
				} else {
					const reason = new Error('Action type is not found')
					reject(reason)
				}
			})
		}

		// -----------------------------------------------------------------
		const onSave = (type) => {
			setPurchaseInvoiceStatus(type).then(() => {
				console.log('mutationVariable', mutationVariable.value)
				addNewPurchaseInvoice()
					.then(() => {
						getStockDebitAmountArray()
					})
					.then(() => {
						if (type === 'save') {
							onClose()
						} else {
							lockPurchaseInvoice()
							onClose()
						}
					})
			})
		}
		// todo ---------------------------------------------------------------------------------------
		return { disableButtons, onSave }
	},
}
</script>
