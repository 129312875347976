<!-- @format -->

<template>
	<div>
		<section>
			<b-field label="Select supplier invoice date">
				<b-datepicker
					v-model="selectedDate"
					:show-week-number="showWeekNumber"
					placeholder="Type or select a date..."
					icon="calendar-today"
					:locale="locale"
					:first-day-of-week="firstDayOfWeek"
					editable
					trap-focus
					:disabled="isDatePickerDisabled"
				>
				</b-datepicker>
			</b-field>
		</section>
		<section>
			<b-field label="Select due date for invoice">
				<b-datepicker
					v-model="selectedDueDate"
					:show-week-number="showWeekNumber"
					placeholder="Type or select a date..."
					icon="calendar-today"
					:locale="locale"
					:first-day-of-week="firstDayOfWeek"
					editable
					:disabled="isDatePickerDisabled"
				>
				</b-datepicker>
			</b-field>
		</section>
		<section>
			<form id="form" class="margin-top-20">
				<vue-form-generator
					:schema="schemaPurchaseInvoice"
					:model="modelPurchaseInvoice"
					:options="formOptionsPurchaseInvoice"
					@validated="onFormValidated"
				>
				</vue-form-generator>
			</form>
		</section>
		<section>
			<b-button
				class="is-primary"
				:disabled="!!isSaveDisabled || !!isDisabled"
				@click="onSubmit"
				>Continue</b-button
			>
		</section>
	</div>
</template>

<script>
import Store from '@/store'
import {
	onMounted,
	onUnmounted,
	ref,
	watch,
	watchEffect,
} from '@vue/composition-api'
import dateformat from 'dateformat'
import useCountriesApi from '@/_srcv2/components/_shared/_countries-list/useCountriesApi.js'

// todo state
import usePurchaseInvoiceHeaderState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState'
import usePurchaseInvoiceState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/usePurchaseInvoiceState.js'

export default {
	name: 'AddPurchaseInvoiceHeader',
	setup() {
		const locale = ref('sv-SE')
		const firstDayOfWeek = ref(1)
		const selectedDate = ref(Store.getters.getToday)
		const selectedDueDate = ref(Store.getters.getToday)
		const {
			purchaseInvoiceIssueDate,
			purchaseInvoiceDueDate,
			modelPurchaseInvoice,
			schemaPurchaseInvoice,
			formOptionsPurchaseInvoice,
			isDatePickerDisabled,
			supplierAddressArray,
			supplierCountryArray,
		} = usePurchaseInvoiceHeaderState()
		const {
			showGetPurchaseInvoiceHeaders,
			showSelectPurchaseInvoiceHeadersDetails,
			showSubSummaryHeaders,
			keySubSummaryHeaders,
			showCreatePurchaseInvoice,
		} = usePurchaseInvoiceState()
		watch(
			() => selectedDate.value,
			(value) => {
				purchaseInvoiceIssueDate.value = dateformat(value, 'yyyy-mm-dd')
				selectedDueDate.value = selectedDate.value
			},
		)
		watch(
			() => selectedDueDate.value,
			(value) => {
				purchaseInvoiceDueDate.value = dateformat(value, 'yyyy-mm-dd')
			},
		)
		const showWeekNumber = ref(true)
		// todo ---------------------------------------------------------------
		const onFormValidated = (isValid, errors) => {
			if (isValid === true) {
				const formValidated =
					modelPurchaseInvoice.purchaseInvoiceSupplierNumber.length > 0
						? true
						: false
				if (formValidated) {
					isSaveDisabled.value = false
				} else if (formValidated === false) {
					isSaveDisabled.value = true
				}
			} else if (errors.length !== 0) {
				isSaveDisabled.value = true
			}
		}
		const isSaveDisabled = ref(true)
		const isDisabled = ref(false)
		// todo -------------------------------------------------------
		const getCountryOfSelectedInvoiceAddress = (array, id) => {
			let filteredAddress = []
			for (let i = 0; i < array.length; i++) {
				if (array[i].id === id) {
					filteredAddress = [...filteredAddress, array[i]]
				}
			}
			return filteredAddress[0].country
		}
		// todo ---------------------------------------------------------
		const { euCountries } = useCountriesApi()
		// todo ---------------------------------------------------------
		watchEffect(() => {
			if (
				modelPurchaseInvoice.supplierAddressId === undefined ||
				modelPurchaseInvoice.supplierAddressId === null ||
				modelPurchaseInvoice.supplierAddressId === ''
			) {
				return
			} else {
				console.log('supplierCountryArray', supplierCountryArray)
				let result =
					getCountryOfSelectedInvoiceAddress(
						supplierCountryArray.value,
						modelPurchaseInvoice.supplierAddressId,
					) || 'Sverige'

				if (result === undefined || null) {
					return
				} else if (result === 'Sverige') {
					modelPurchaseInvoice.purchaseInvoiceType = 'Inland'
					modelPurchaseInvoice.purchaseInvoiceExchangeUnit = 'kr.'
					modelPurchaseInvoice.purchaseInvoiceExchangeRate = 1.0
				} else if (euCountries.value.includes(result)) {
					modelPurchaseInvoice.purchaseInvoiceType = 'Inside EU'
				} else {
					modelPurchaseInvoice.purchaseInvoiceType = 'Outside EU'
				}
			}
		})

		// ? ----------------------------------------------------------------
		const onSubmit = () => {
			if (
				confirm(
					`Are you sure order's type is ${modelPurchaseInvoice.purchaseInvoiceType} \n To continue to register purchase invoice press 'ok' \n To cancel press 'cancel'`,
				)
			) {
				isDisabled.value = true
				showSelectPurchaseInvoiceHeadersDetails.value = false
				showGetPurchaseInvoiceHeaders.value = false
				showSubSummaryHeaders.value = true
				keySubSummaryHeaders.value += 1
				showCreatePurchaseInvoice.value = true
			}
		}
		// ? --------------------------------------------------------
		const setAddressDefaultValues = (array) => {
			if (array.length === 1) {
				modelPurchaseInvoice.supplierAddressId = array[0].id
			}
		}
		const condition = true
		const compPromise = () => {
			console.log('compPromise is initialized')
			return new Promise((resolve, reject) => {
				if (condition) {
					const array = ref([])
					array.value = supplierAddressArray.value.map((item) => {
						return {
							name: `${item.address_nickname} ${item.line_1} ${item.line_2} ${item.post_code} ${item.city}`,
							id: item.address_id,
						}
					})
					// ? ---------------------------------------------------------------------------------------------
					console.log('array.value.length', array.value.length)
					resolve(array.value)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onMounted(() => {
			isDatePickerDisabled.value = false
			modelPurchaseInvoice.purchaseInvoiceSupplierNumber = ''
			modelPurchaseInvoice.purchaseInvoiceExchangeUnit = 'kr.'
			modelPurchaseInvoice.purchaseInvoiceExchangeRate = 1.0
			console.log('supplierAddressArray.value', supplierAddressArray.value)
			compPromise().then((array) => {
				setAddressDefaultValues(array)
			})
		})
		onUnmounted(() => {
			isDatePickerDisabled.value = false
			modelPurchaseInvoice.purchaseInvoiceSupplierNumber = ''
			modelPurchaseInvoice.purchaseInvoiceExchangeUnit = 'kr.'
			modelPurchaseInvoice.purchaseInvoiceExchangeRate = 1.0
		})
		// todo --------------------------------------------------------------------------------------------
		return {
			locale,
			firstDayOfWeek,
			selectedDate,
			selectedDueDate,
			isDatePickerDisabled,
			showWeekNumber,
			modelPurchaseInvoice,
			schemaPurchaseInvoice,
			formOptionsPurchaseInvoice,
			onFormValidated,
			onSubmit,
			isSaveDisabled,
			isDisabled,
			showGetPurchaseInvoiceHeaders,
		}
	},
}
</script>

<style scoped></style>
