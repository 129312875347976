<template>
	<layout-default-new>
		<div style="background-color: white">
			<img
				v-if="componentLoading"
				src="@/_srcv2/components/_shared/icon/200w.gif"
			/>
		</div>
		<div v-if="!componentLoading">
			<div>
				<sub-navbar page="Purchase Invoice" />
			</div>
			<div>
				<HeroBox
					title="Add Purchase Invoice"
					subtitle="Select Supplier to add purchase Invoice"
					color="is-danger"
				/>
			</div>
			<div v-if="showSearchBox">
				<ve-table
					:rows="rowsList"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:sort-options="sortOptions"
				>
				</ve-table>
			</div>
			<div v-if="!showSearchBox">
				<b-button class="is-primary margin-top-20" @click="onClose"
					>X Close</b-button
				>
			</div>
			<div class="margin-top-30" v-if="showGetPurchaseInvoiceHeaders">
				<get-purchase-invoice-headers :key="keyPurchaseInvoiceHeaders" />
			</div>
			<div>
				<AddPurchaseInvoiceHeader
					v-if="showSelectPurchaseInvoiceHeadersDetails"
				/>
			</div>
			<div id="addPurchaseInvoiceLines" v-if="showSubSummaryHeaders">
				<div class="margin-top-30">
					<sub-summary-purchase-invoice-headers :key="keySubSummaryHeaders" />
				</div>
				<div class="margin-top-30">
					<current-purchase-invoice :key="keySubSummaryHeaders" />
				</div>
				<div>
					<search-stock-item-to-add-to-purchase-invoice
						:key="keySubSummaryHeaders"
					/>
				</div>
				<div class="margin-top-30">
					<create-purchase-invoice />
				</div>
			</div>
			<div class="max-width-150">
				<add-purchase-invoice-lines-modal-frame
					v-if="isModalVisible"
					@close="closeModal"
					:key="keyModal"
				/>
			</div>
		</div>
	</layout-default-new>
</template>

<script>
// todo components
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import AddPurchaseInvoiceHeader from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/add-purchase-invoice-header/AddPurchaseInvoiceHeader.vue'
import GetPurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/GetPurchaseInvoiceHeaders'
// import SelectPurchaseInvoiceHeadersDetails from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/select-purchase-invoice-headers-details/SelectPurchaseInvoiceHeadersDetails'
import SubSummaryPurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/sub-summary/SubSummaryPurchaseInvoiceHeaders.vue'
import CreatePurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/create-purchase-invoice/CreatePurchaseInvoice.vue'
import CurrentPurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/current-purchase-invoice/CurrentPurchaseInvoice.vue'
import SearchStockItemToAddToPurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/search-stock-item/SearchStockItemToAddToPurchaseInvoice.vue'
import AddPurchaseInvoiceLinesModalFrame from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/add-purchase-invoice-lines/AddPurchaseInvoiceLinesModalFrame'

import { reactive, onMounted, ref, computed } from '@vue/composition-api'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'

// todo state
import usePurchaseInvoiceState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/usePurchaseInvoiceState'
import usePurchaseInvoiceHeaderState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState'
import usePurchaseInvoiceModalState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/add-purchase-invoice-lines/usePurchaseInvoiceModalState.js'

// todo GraphQL
import GetActiveSupplierListQuery from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/GetActiveSupplierListQuery.graphql'

export default {
	name: 'AddPurchaseInvoice',
	components: {
		AddPurchaseInvoiceLinesModalFrame,
		SubNavbar,
		HeroBox,
		AddPurchaseInvoiceHeader,
		GetPurchaseInvoiceHeaders,
		// SelectPurchaseInvoiceHeadersDetails,
		SubSummaryPurchaseInvoiceHeaders,
		CreatePurchaseInvoice,
		CurrentPurchaseInvoice,
		SearchStockItemToAddToPurchaseInvoice,
	},
	setup() {
		const componentLoading = ref(true)
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'customer_title', type: 'asc' },
		}
		// add document transaction record for purchase invoice
		const record = reactive({
			document_transaction_id: '',
			transaction_type: 'purchase sale invoice',
			our_company: '',
			customer_id: '',
			supplier_invoice_number: '',
			invoice_number: computed(
				() => `${record.customer_id} - ${record.supplier_invoice_number}`,
			),
			invoice_date: '',
			invoice_due_date: '',
			transaction_date: '',
			invoice_type: '',
			invoice_exchange_unit: '',
			invoice_exchange_rate: '',
		})
		console.log(record)
		const { isModalVisible, keyModal } = usePurchaseInvoiceModalState()
		const closeModal = () => (isModalVisible.value = false)
		const showModal = () => (isModalVisible.value = true)
		// todo get columns
		const {
			columnsList,
			showSearchBox,
			showGetPurchaseInvoiceHeaders,
			showSelectPurchaseInvoiceHeadersDetails,
			showSubSummaryHeaders,
			keySubSummaryHeaders,
		} = usePurchaseInvoiceState()
		// todo get rows
		const rowsList = ref([])
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const {
			refetch: refetchCustomers,
			loading,
			onResult,
		} = useQuery(
			GetActiveSupplierListQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const refetchData = () => {
			refetchCustomers().then((result) => {
				rowsList.value = result.data.customers
				console.log('rowsList.value', rowsList.value)
			})
		}
		onResult((result) => {
			console.log('onResult result', result)
		})
		const setInitialState = () => {
			componentLoading.value = false
			showSearchBox.value = true
			showGetPurchaseInvoiceHeaders.value = false
			showSelectPurchaseInvoiceHeadersDetails.value = false
		}
		onMounted(() => {
			refetchData()
			setInitialState()
		})
		const {
			ourCompanyPurchaseInvoice,
			customerIdPurchaseInvoice,
			customerTitlePurchaseInvoice,
			customerNicknamePurchaseInvoice,
			keyPurchaseInvoiceHeaders,
			supplierAddressArray,
			supplierCountryArray,
			schemaPurchaseInvoice,
		} = usePurchaseInvoiceHeaderState()
		// todo ------------------------------------------------------------------------
		const getAddressArray = (row) => {
			return row._addresses.map((row) => {
				return {
					name: `${row.address_nickname} ${row.line_1} ${row.line_2} ${row.post_code} ${row.city}`,
					id: row.address_id,
				}
			})
		}
		const getCountryArray = (row) => {
			return row._addresses.map((address) => {
				return { id: address.address_id, country: address.country }
			})
		}

		const setPurchaseInvoiceHeaders = (row) => {
			return new Promise((resolve, reject) => {
				console.log(
					'setPurchaseInvoiceHeaders schemaPurchaseInvoice',
					schemaPurchaseInvoice,
				)
				if (row !== null || undefined) {
					ourCompanyPurchaseInvoice.value = Store.getters.getUserCurrentCompany
					customerIdPurchaseInvoice.value = row.customer_id
					customerTitlePurchaseInvoice.value = row.customer_title
					customerNicknamePurchaseInvoice.value = row.customer_nickname
					supplierAddressArray.value = row._addresses
					schemaPurchaseInvoice.groups[0].fields[0].values =
						getAddressArray(row)
					supplierCountryArray.value = getCountryArray(row)
					resolve(true)
				} else {
					const reason = new Error('Data could not be fetched from table')
					reject(reason)
				}
			})
		}

		const setComponentState = () => {
			showSearchBox.value = false
			showSelectPurchaseInvoiceHeadersDetails.value = true
			showGetPurchaseInvoiceHeaders.value = true
			showSelectPurchaseInvoiceHeadersDetails.value = false
			setTimeout(() => {
				showSelectPurchaseInvoiceHeadersDetails.value = true
				componentLoading.value = false
			}, 200)
		}

		const onCellClick = (params) => {
			componentLoading.value = true
			setPurchaseInvoiceHeaders(params.row).then(() => setComponentState())
		}
		const onRowDoubleClick = (params) => {
			setPurchaseInvoiceHeaders(params.row).then(() => setComponentState())
		}
		// -----------------------------------------------------------------
		const onClose = () => {
			showSearchBox.value = true
			showGetPurchaseInvoiceHeaders.value = false
			showSubSummaryHeaders.value = false
			showSelectPurchaseInvoiceHeadersDetails.value = false
		}
		return {
			sortOptions,
			isModalVisible,
			keyModal,
			showModal,
			closeModal,
			loading,
			rowsList,
			columnsList,
			onCellClick,
			onRowDoubleClick,
			showSearchBox,
			showGetPurchaseInvoiceHeaders,
			showSelectPurchaseInvoiceHeadersDetails,
			showSubSummaryHeaders,
			keySubSummaryHeaders,
			onClose,
			keyPurchaseInvoiceHeaders,
			componentLoading,
		}
	},
}
</script>

<style scoped></style>
